<template>
  <div id="products-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <div class="vx-row">
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'all'}" @click="getItems('all')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="DatabaseIcon"
              icon-right
              :statistic="counts('all')"
              :statisticTitle="$t('all')" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'active'}" @click="getItems('active')">
            <statistics-card-line
              role="button"
              hideChart
              class="mb-base"
              icon="CloudIcon"
              icon-right
              :statistic="counts('active')"
              :statisticTitle="$t('active')"
              color="success" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'passive'}" @click="getItems('passive')">
            <statistics-card-line
              role="button"
              hideChart
              class="mb-base"
              icon="CloudOffIcon"
              icon-right
              :statistic="counts('passive')"
              :statisticTitle="$t('passive')"
              color="passive" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'sale'}" @click="getItems('sale')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="BookmarkIcon"
              icon-right
              :statistic="counts('sale')"
              :statisticTitle="$t('sale')"
              color="danger" />
            </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'express'}" @click="getItems('express')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TruckIcon"
              icon-right
              :statistic="counts('express')"
              :statisticTitle="$t('express')"
              color="warning" />
          </a>
        </div>
        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6">
          <a href="#" class="stat_btn" :class="{'current': current === 'sold'}" @click="getItems('sold')">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="AlertTriangleIcon"
              icon-right
              :statistic="counts('sold')"
              :statisticTitle="$t('soldOut')"
              color="danger" />
          </a>
        </div>
      </div>
      <vx-card v-if="!isLoading">
        <vs-table ref="table" :data="filteredItems">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-wrap-reverse items-center">
              <router-link v-if="$acl.check('admin')" :to="{name: 'productCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
              </router-link>
            </div>
            <div>
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ $t('tableColumns') }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <ul class="centerx p-5">
                    <li v-for="(oKey, idx) in prodColumns" :key="idx" class="py-1">
                      <vs-checkbox v-model="columns" :vs-value="oKey" class="capitalize">{{ $t(`fields.${oKey}`) }}</vs-checkbox>
                    </li>
                  </ul>
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ currentx * filter.limit - (filter.limit - 1) }} - {{ products.pagination.all - currentx * filter.limit > 0 ? currentx * filter.limit : products.pagination.all }} of {{ products.pagination.all }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="setLimit(opt)">
                    <span>{{ opt }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <div class="inline-block relative mb-4">
                <vs-input :placeholder="$t('search')" class="custom-search-input" v-model="searchQuery" />
                <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
              </div>
            </div>
          </div>
          <template slot="thead">
            <vs-th v-if="columns.indexOf('image') >= 0" class="w-4">{{ $t('fields.image') | capitalize }}</vs-th>
            <vs-th v-if="columns.indexOf('id') >= 0" class="w-4">{{ $t('fields.id') }}</vs-th>
            <vs-th v-if="columns.indexOf('code') >= 0" class="w-4">{{ $t('fields.code') }}</vs-th>
            <vs-th v-if="columns.indexOf('shelf_code') >= 0" class="w-4">{{ $t('fields.shelf_code') }}</vs-th>
            <vs-th v-if="columns.indexOf('bar_code') >= 0" class="w-4">{{ $t('fields.bar_code') }}</vs-th>
            <vs-th v-if="columns.indexOf('name_tm') >= 0" sort-key="name_tm" class="max-w-xs">{{ $t('fields.name_tm') }}</vs-th>
            <vs-th v-if="columns.indexOf('name_ru') >= 0" sort-key="name_ru" class="max-w-xs">{{ $t('fields.name_ru') }}</vs-th>
            <vs-th v-if="columns.indexOf('name_en') >= 0" sort-key="name_en" class="max-w-xs">{{ $t('fields.name_en') }}</vs-th>
            <vs-th v-if="columns.indexOf('categories') >= 0" sort-key="category">{{ $t('category') }}</vs-th>
            <vs-th v-if="columns.indexOf('brandId') >= 0" sort-key="brand">{{ $t('brand') }}</vs-th>
            <vs-th v-if="columns.indexOf('likes') >= 0" sort-key="popularity">{{ $t('popularity') | capitalize }}</vs-th>
            <vs-th v-if="columns.indexOf('isActive') >= 0">{{ $t('fields.active') }}</vs-th>
            <vs-th v-if="columns.indexOf('canPublished') >= 0">{{ $t('fields.canPublished') }}</vs-th>
            <vs-th v-if="columns.indexOf('type') >= 0" >{{ $t('fields.type') }}</vs-th>
            <vs-th v-if="columns.indexOf('price') >= 0">{{ $t('fields.price') }}</vs-th>
            <vs-th v-if="columns.indexOf('given_price') >= 0">{{ $t('fields.given_price') }}</vs-th>
            <vs-th v-if="columns.indexOf('stock_quantity') >= 0">{{ $t('fields.stock_quantity') }}</vs-th>
            <vs-th>{{ $t('fields.actions') }}</vs-th>
          </template>
          <template v-if="!updatingList" slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :class="['pt-3', !tr.isActive ? 'text-danger' : '']" :state="!data[indextr].canPublished ? 'danger' : ''">
              <vs-td v-if="columns.indexOf('image') >= 0">
                <div class="img" :class="{'sale': data[indextr].discount}" role="button" @click="showData(tr.uuid)">
                  <img v-if="tr.product_images[0]" :src="`${$baseUrl}/products/preview/${tr.product_images[0].uuid}.webp`" alt="">
                </div>
              </vs-td>
              <vs-td v-if="columns.indexOf('id') >= 0" :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
              <vs-td v-if="columns.indexOf('code') >= 0" :data="data[indextr].code">{{ data[indextr].code }}</vs-td>
              <vs-td v-if="columns.indexOf('shelf_code') >= 0" :data="data[indextr].shelf_code">{{ data[indextr].shelf_code }}</vs-td>
              <vs-td v-if="columns.indexOf('bar_code') >= 0" :data="data[indextr].bar_code">{{ data[indextr].bar_code }}</vs-td>
              <vs-td v-if="columns.indexOf('name_tm') >= 0" :data="data[indextr].name_tm">{{ data[indextr].name_tm }}</vs-td>
              <vs-td v-if="columns.indexOf('name_ru') >= 0" :data="data[indextr].name_ru">{{ data[indextr].name_ru }}</vs-td>
              <vs-td v-if="columns.indexOf('name_en') >= 0" :data="data[indextr].name_en">{{ data[indextr].name_en }}</vs-td>
              <vs-td v-if="columns.indexOf('categories') >= 0" :data="data[indextr].category" class="w-48">
                <template v-if="data[indextr].categories && data[indextr].categories.length">
                  <span v-for="(category, idx) in data[indextr].categories" :key="category.id">
                    {{ category[`name_${$i18n.locale}`] }}<template v-if="idx + 1 < data[indextr].categories.length">;</template>
                  </span>
                </template>
                <span v-else> --- </span>
              </vs-td>
              <vs-td v-if="columns.indexOf('brandId') >= 0" :data="data[indextr].brand">
                {{ data[indextr].brand ? data[indextr].brand[`name_${$i18n.locale}`] : '' }}
              </vs-td>
              <vs-td v-if="columns.indexOf('likes') >= 0" :data="data[indextr].popularity">
                <vs-progress :percent="(data[indextr].popularity)" :color="getPopularityColor(data[indextr].popularity)" class="shadow-md" />
              </vs-td>
              <vs-td v-if="columns.indexOf('isActive') >= 0" :data="data[indextr].isActive">
                <vs-chip :color="data[indextr].isActive ? 'success' : 'danger'">
                  <feather-icon v-if="data[indextr].isActive" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-if="columns.indexOf('canPublished') >= 0" :data="data[indextr].canPublished">
                <vs-chip :color="data[indextr].canPublished ? 'success' : 'danger'">
                  <feather-icon v-if="data[indextr].canPublished" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-if="columns.indexOf('type') >= 0" :data="data[indextr].type">
                <vs-chip :color="data[indextr].type === 'both' ? 'success' : data[indextr].type === 'market' ? 'primary' : 'warning'">
                  {{ data[indextr].type }}
                </vs-chip>
              </vs-td>
              <vs-td v-if="columns.indexOf('price') >= 0" class="font-semibold" :data="data[indextr].price">
                {{ data[indextr].price ? data[indextr].price.toFixed(2) : '' }}
              </vs-td>
              <vs-td v-if="columns.indexOf('given_price') >= 0" class="font-semibold" :data="data[indextr].given_price">
                {{ data[indextr].given_price ? data[indextr].given_price.toFixed(2) : '' }}
              </vs-td>
              <vs-td v-if="columns.indexOf('stock_quantity') >= 0" :data="data[indextr].stock_quantity" class="font-semibold" :class="[{'text-danger': data[indextr].stock_quantity < data[indextr].stock_min}]">
                {{ data[indextr].stock_quantity }}
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" @click.stop="showData(tr.uuid)" />
                <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr.uuid)" />
                <!-- <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isActive)" /> -->
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div v-if="updatingList" class="flex w-full h-full p-24">
          <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
        </div>
        <vs-row class="mt-4">
          <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
export default {
  components: {StatisticsCardLine},
  data () {
    return {
      products: {},
      current: this.$route.query.current || 'all',
      isLoading: true,
      isMounted: false,
      columns: ['image', 'code', 'bar_code', 'name_tm', 'brandId', 'categories', 'likes', 'price', 'type', 'stock_quantity'],
      perPageOptions: [10, 15, 20, 50, 100],
      currentx: Number(this.$route.query.page || 1),
      searchQuery: this.$route.query.q || '',
      timer: null,
      itemCounts: null,
      updatingList: false,
      filter: {
        limit: Number(this.$route.query.limit || 20),
        offset: Number(this.$route.query.limit || 20) * (Number(this.$route.query.page || 1) - 1),
        keyword: this.$route.query.q || '',
        isActive: this.$route.query.current === 'active' ? true : this.$route.query.current === 'passive' ? false : null,
        canPublished: this.$route.query.current === 'active' ? true : this.$route.query.current === 'passive' ? false : null,
        hasDiscount: this.$route.query.current === 'sale' ? true : null,
        isEnoughInStock: this.$route.query.current === 'sold' ? true : null,
        type: this.$route.query.current === 'express' ? 'express' : null
      }
    }
  },
  watch: {
    filter: { handler () { this.fetchData() }, deep: true },
    searchQuery (newVal) {
      if (newVal.length > 2) {
        if (this.timer !== null) { clearTimeout(this.timer) }
        this.timer = setTimeout(() => {
          this.filter.keyword = newVal
          const query = { ...this.$route.query, q: newVal }
          this.$router.replace({ query })
          this.currentx = 1
        }, 1000)
      }
      if (!newVal) {
        const query = { ...this.$route.query, q: null }
        this.$router.replace({ query })
        this.filter.keyword = ''
      }
    }
  },
  computed: {
    pages () { return this.products.pagination && this.products.pagination.all ? Math.ceil(this.products.pagination.all / this.filter.limit) : 1 },
    getName () {
      return (cId) => {
        const cat =  this.products.find(cat => cat.id === cId)
        return cat ? cat.name_en : '---'
      }
    },
    filteredItems () { return this.products.data },
    counts () { return (status) => { return this.isLoading ? 0 : this.itemCounts[status] } },
    prodColumns () {
      const cols = ['uuid', 'description_tm', 'description_ru', 'description_en', 'price_express', 'isBulk', 'price_bulk', 'bulk_min', 'express_max', 'stock_min', 'ownerId', 'createdAt', 'updatedAt', 'discount', 'product_images', 'weight', 'volume', 'ordered', 'unordered', 'in_carrier_stock', 'isEnoughInStock', 'brand', 'isNew', 'isHit', 'supplierId']
      return this.products.data[0] ? Object.keys(this.products.data[0]).filter((col) => !cols.includes(col)) : []
    }
  },
  async created () {
    await this.fetchData()
    await this.getCounts()
    this.isLoading = false
    // this.isMounted = true
  },
  methods: {
    async fetchData () {
      await this.$http.get('/products', { params: this.filter }).then(response => {
        this.products = response.data
        this.updatingList = false
      }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    async getCounts () {
      await this.$http.get('/statistics/item-counts', { params: { model: 0 } }).then(response => {
        this.$store.dispatch('setSoldOut', response.data.sold)
        this.itemCounts = response.data
      })
    },
    getPopularityColor (num) {
      if (num > 80)  return 'success'
      if (num > 60)  return 'primary'
      if (num >= 40) return 'warning'
      if (num < 40)  return 'danger'
      return 'primary'
    },
    editData (id) { this.$router.push(`/products/${id}/edit`) },
    showData (id) { this.$router.push(`/products/${id}`) },
    changeStatus (id, status) {
      this.$http.patch(`/products/edit/${id}`, { isActive: !status }).then(response => {
        if (response.status === 200) {
          this.notify('success', this.$t('notify.success'), 'Product status successfully deleted!')
          this.fetchData()
        }
      }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    getItems (e) {
      this.current = e
      this.currentx = 1
      this.filter.offset = 0
      this.filter.isActive = null
      this.filter.canPublished = null
      this.filter.hasDiscount = null
      this.filter.isEnoughInStock = null
      this.filter.type = null
      this.updatingList = true
      switch (e) {
      case 'all':
        break
      case 'active':
        this.filter.isActive = true
        this.filter.canPublished = true
        break
      case 'passive':
        this.filter.isActive = false
        break
      case 'sale':
        this.filter.hasDiscount = true
        break
      case 'express':
        this.filter.type = 'express'
        break
      case 'sold':
        this.filter.isEnoughInStock = false
        break
      default:
        break
      }
      const query = { ...this.$route.query, current: e }
      this.$router.replace({ query })
    },
    paginateTo (e) {
      if (this.isMounted) {
        this.filter.offset = this.filter.limit * (this.currentx - 1)
        const query = { ...this.$route.query, page: e }
        this.$router.replace({ query })
        this.updatingList = true
      } else {
        this.isMounted = true
      }
    },
    setLimit (e) {
      if (this.filter.limit !== e) {
        this.currentx = 1
        this.filter.offset = 0
        this.filter.limit = e
        const query = { ...this.$route.query, limit: e }
        this.$router.replace({ query })
        this.updatingList = true
      }
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
#products-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
  .stat_btn {
    color: #626262;
    .vx-card {
      border: 1px solid transparent;
    }
    &:hover {
      cursor: pointer;
      color: #7367F0;
      .vx-card {
        border: 1px solid #666666;
      }
    }
    &:active {
      .vx-card {
        border: 1px solid green;
      }
    }
    &.current {
      color: #7367F0;
      .vx-card {
        border: 1px solid #7367F0;
      }
    }
  }
  .img {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    &.sale {
      outline: rgba(234,84,85,0.8) solid 3px;
      box-shadow: 0px 0px 15px rgba(234,84,85,1);
    }
  }
}
</style>